import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.questions,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Часто задаваемые вопросы")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v("Добавить")],1)],1)]},proxy:true},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:`item.position`,fn:function({ item }){return [_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"9"}},[_c(VTextField,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},model:{value:(item.position),callback:function ($$v) {_vm.$set(item, "position", $$v)},expression:"item.position"}})],1),_c(VCol,{staticClass:"pa-0 d-flex justify-center align-center"},[_c(VIcon,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setPosition(item._id, item.position)}}},[_vm._v("mdi-check")])],1)],1)]}},{key:`item.enabled`,fn:function({ item }){return [(item.enabled)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:`item.edit`,fn:function({ item }){return [_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }